import styles from "./styles.module.scss";
import WorkHistoryItemIcon from "../../../../assets/icons/workHistoryItem.png";

const WorkHistoryItem = () => {
  return <div className={styles.workHistory}>
      <img src={WorkHistoryItemIcon} alt={'work history icon'} />
      <p>Today</p>
  </div>
}

export default WorkHistoryItem