import useIsMobile from '../../hooks/useIsMobile';
import WebsocketWrapper from '../../websocket/WebsocketWrapper';

import { AgentChat, QuickLinks, WorkHistory, Worksheet } from '../../pageComponents';

import styles from './styles.module.scss';

const Dashboard = () => {
  const isMobileView = useIsMobile();
  return (
    <div className={styles.dashboardContainer}>
      <WebsocketWrapper>
        <AgentChat />
      </WebsocketWrapper>
      <Worksheet />
      {!isMobileView &&
        <div style={{ height: '100%', width: '30%' }}>
          <WorkHistory />
          <QuickLinks />
        </div>
      }
    </div>
  );
}

export default Dashboard;
