import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import cx from 'classnames';

import { WorksheetJob } from './components';
import { useSelector } from 'react-redux';

import { ReactComponent as QuickLinksIcon } from '../../assets/icons/quickLinks.svg'
import { ReactComponent as WorkHistoryIcon } from '../../assets/icons/workHistory.svg';
import CloseIcon from '../../assets/icons/closeIcon.png';

import WorkHistory from '../WorkHistory/WorkHistory';
import QuickLinks from '../QuickLinks/QuickLinks';

import styles from './styles.module.scss';

const RENDERED_COMPONENT = {
  WORK_HISTORY: 'WORK_HISTORY',
  QUICK_LINKS: 'QUICK_LINKS'
};

const Worksheet = () => {
  const { worksheetList } = useSelector((state) => state.worksheet);
  const [renderedComponentName, setRenderedComponentName] = useState('');

  const renderedComponent = useMemo(() => {
    switch (renderedComponentName) {
      case RENDERED_COMPONENT.QUICK_LINKS:
        return {
          component: <QuickLinks />,
          title: 'QUICK LINKS',
          icon: <QuickLinksIcon />
        }
      case RENDERED_COMPONENT.WORK_HISTORY:
        return {
          component: <WorkHistory />,
          title: 'WORK HISTORY',
          icon: <WorkHistoryIcon />
        }
      default: return null
    }
  }, [renderedComponentName]);

  console.log(renderedComponent, renderedComponentName)
  return (
    <div className={styles.worksheet}>
      {!renderedComponent ?
        <>
          <div className={styles.worksheetHeader}>
            <div className={styles.worksheetHeaderTitle}>
              <p>WORKSHEET</p>
              <p>{dayjs().format('MMMM D, YYYY')}</p>
            </div>
            <div className={styles.worksheetHeaderLinks}>
              <WorkHistoryIcon onClick={() => setRenderedComponentName(RENDERED_COMPONENT.WORK_HISTORY)} />
              <QuickLinksIcon onClick={() => setRenderedComponentName(RENDERED_COMPONENT.QUICK_LINKS)} />
            </div>
          </div>
          <div className={styles.worksheetContent}>
            {worksheetList.map((worksheetObj) => <WorksheetJob worksheet={worksheetObj} />)}
          </div>
        </>
        :
        <div className={styles.renderedComponentContainer}>
          <div className={styles.worksheetHeader}>
            <div className={styles.worksheetHeaderTitle}>
              <p>{renderedComponent?.title}</p>
              <p></p>
            </div>
            <div className={cx(styles.worksheetHeaderLinks, styles.activeSVG)}>
              {renderedComponent?.icon}
              <img onClick={() => setRenderedComponentName('')} src={CloseIcon} alt={'close-icon'} />
            </div>
          </div>
          {renderedComponent?.component}
        </div>
      }
    </div>
  )
}

export default Worksheet