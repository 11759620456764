import { useEffect, useRef, useState } from 'react';

import { ChatComponent, ChatInputComponent } from './components';
import { ReactComponent as ColapseIcon } from '../../assets/icons/chatColapseIcon.svg'
import useIsMobile from '../../hooks/useIsMobile';

import styles from './styles.module.scss';

const AgentChat = () => {
  const agentChatRef = useRef(null);
  const [heightStage, setHeightStage] = useState(0);
  const isMobileView = useIsMobile();

  const handleHeight = (isHeightUp) => {
    setHeightStage((prevStage) => {
      if (isHeightUp && prevStage < 2) return prevStage + 1;
      if (!isHeightUp && prevStage > 0) return prevStage - 1;
      return prevStage;
    });
  };

  useEffect(() => {
    if (isMobileView) {
      if (agentChatRef.current) {
        const parent = agentChatRef.current.parentElement;
        if (parent) {
          const parentHeight = parent.offsetHeight;
          let newHeight;

          switch (heightStage) {
            case 0:
              newHeight = `90px`;
              break;
            case 1:
              newHeight = `${parentHeight * 0.5}px`;
              break;
            case 2:
              newHeight = `${parentHeight}px`;
              break;
            default:
              newHeight = `90px`;
          }
          agentChatRef.current.style.height = newHeight;
        }
      }
    } else agentChatRef.current.style.height = '100%';
  }, [heightStage, isMobileView]);

  return <div className={styles.agentChat} ref={agentChatRef}>
    <div className={styles.agentChatHeader}>
      <div className={styles.agentChatHeaderImg} />
      <div className={styles.agentChatHeaderDescription}>
        <p>Nora</p>
        <p>Your Realestate Personal Assistant</p>
      </div>
      <div className={styles.chatColapseIcon}>
        <ColapseIcon className={heightStage == 0 ? styles.chatColapseIconDisabled : ''} onClick={() => handleHeight(false)} />
        <ColapseIcon className={heightStage == 2 ? styles.chatColapseIconDisabled : ''} onClick={() => handleHeight(true)} style={{ transform: 'rotate(180deg)' }} />
      </div>
    </div>
    <ChatComponent />
    <ChatInputComponent />
  </div>
}

export default AgentChat