import cx from "classnames";

import styles from './styles.module.scss';

const ChatMessage = ({message, isSent}) => {
  return <div className={cx(styles.chatMessage, isSent ?  styles.chatMessageSent : '' )}>
    <p>{message}</p>
  </div>
}

export default ChatMessage;