import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import TextArea from 'antd/es/input/TextArea';
import Microphone from '../../../../assets/icons/microphone.png'
import { ArrowUpOutlined } from '@ant-design/icons';

import { appendMessage, setLoadingState } from '../../../../store/slices/chatSlice';
import { SocketContext } from '../../../../websocket/WebsocketWrapper';
import { CHAT_SENDER } from '../../../../utils/chatUtils';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'

import styles from './styles.module.scss';

const ChatInputComponent = () => {
  const dispatch = useDispatch();

  const { websocketConnected } = useSelector((state) => state.chat);

  const { websocket } = useContext(SocketContext);
  const [messages, setMessage] = useState('');
  const [minRows, setMinRows] = useState(1);
  const [size, setSize] = useState({ width: 0, height: 0 }); 

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();
  const containerRef = useRef();

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const { width, height } = containerRef.current.getBoundingClientRect();
        setSize({ width, height });
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => resizeObserver.disconnect();
  }, []);


  const sendMessage = useCallback(async () => {
    resetTranscript()
    websocket.emit('new_message', {
      message: messages,
      id: `${websocket.id}${Math.random()}`,
      socketID: websocket.id,
    });
    dispatch(appendMessage({ sender: CHAT_SENDER.USER, message: messages }));
    dispatch(setLoadingState(true))
    setMessage('');
  }, [messages, websocket, setMessage, dispatch]);

  useEffect(() => { transcript && setMessage(transcript) }, [transcript])

  useEffect(() => {
    const updateRows = () => {
      if (containerRef.current) {
        const containerHeight = containerRef.current.offsetHeight;
        const lineHeight = 22;
        setMinRows(Math.floor(containerHeight / lineHeight));
      }
    };
    updateRows();
    window.addEventListener("resize", updateRows);
    return () => {
      window.removeEventListener("resize", updateRows);
    };
  }, [websocketConnected, size]);

  const handleListening = useCallback(() => {
    if (listening) {
      SpeechRecognition.stopListening()
    } else {
      SpeechRecognition.startListening({ continuous: true })
      resetTranscript()
    }
  }, [listening])

  return <div className={styles.chatInput}>
    {websocketConnected || true ?
      <>
        <div className={styles.chatInputTextArea} >
          <div style={{ height: '100%', width: '100%' }} ref={containerRef}>
            <TextArea
              id={"textarea"}
              autoSize={{ minRows: minRows }}
              value={messages}
              onChange={(e) => setMessage(e.target.value)}
              onPressEnter={(e) => {
                e.preventDefault()
                !!messages.length && sendMessage()
              }}
            />
          </div>
          <div className={styles.chatInputTextAreaSend} onClick={sendMessage}>
            <ArrowUpOutlined />
          </div>
        </div>
        <div className={styles.chatInputMicrophone} onClick={() => handleListening()}>
          <img src={Microphone} alt={'microphone'} />
          <p>{listening ? 'Listening' : 'off'}</p>
        </div>
      </> :
      <div style={{ textAlign: 'center', width: '100%' }}>
        <p> Websocket is disconnected</p>
      </div>
    }
  </div>
}

export default ChatInputComponent;