import { Button } from 'antd';
import WorksheetTask from '../WorksheetTask/WorksheetTask';
import { useSelector } from 'react-redux';
import excel from '../../../../assets/icons/excel.png';

import styles from './styles.module.scss';

const WorksheetJob = ({ worksheet }) => {

  return <div className={styles.worksheetJob}>
    <div className={styles.worksheetJobDetails}>
      <div className={styles.worksheetJobHeader}>
        <p>{worksheet?.headline}</p>
        <p>{worksheet?.description}</p>
      </div>
      {/* <Button>DISCUSS</Button> */}
    </div>
    <div className={styles.worksheetJobTasks}>
      {worksheet?.tasks?.map((obj, index) => <WorksheetTask items={obj.items} headline={obj?.headline} key={index} />)}
      {worksheet?.spreadsheet_url &&
        <div className={styles.worksheetJobFile}>
          <img src={excel} alt='excel' />
          <p>Search Results - </p>
          <a href={worksheet?.spreadsheet_url} target='_blank'>Open File</a>
        </div>
      }
    </div>
  </div>
}

export default WorksheetJob