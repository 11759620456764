import {ReactComponent as HistoryIcon }  from "../../assets/icons/workHistory.svg";
import WorkHistoryItem from "./components/WorkHistoryItem/WorkHistoryItem";

import styles from "./styles.module.scss";

const WorkHistory = () => {
  return <div className={styles.workHistory}>
    <div className={styles.workHistoryHeader}>
      <p>WORK HISTORY</p>
      <HistoryIcon/>
    </div>
    <div className={styles.workHistoryContainer}>
      <WorkHistoryItem/>
    </div>
    {/* <p className={styles.workHistoryLoadMore}>Load More</p> */}
  </div>
}

export default WorkHistory