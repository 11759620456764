

import { createContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { io } from 'socket.io-client';
import { appendMessage, setLoadingState, setWebsocketState } from '../store/slices/chatSlice';
import { CHAT_SENDER } from '../utils/chatUtils';
import { appendWorksheet } from '../store/slices/worksheetSlice';
import { useAuth } from '../context/AuthContext';

const URL = `${process.env.REACT_APP_BASE_URL}`;

const EVENTS_ACTION_TYPE = {
  RESPONSE: 'response',
  NEW_MESSAGE: 'new_message',
  RESPONSE_ERROR: 'error'
}

export const SocketContext = createContext();

const WebsocketWrapper = ({
  children
}) => {
  const dispatch = useDispatch();
  const { token } = useAuth();
  const [websocket, setWebsocket] = useState();

  const accessToken = token ?? false;

  const onResponse = async (value) => {
    dispatch(appendMessage({ sender: CHAT_SENDER.AGENT, message: value.message }))
    value?.worksheet && dispatch(appendWorksheet(value?.worksheet))
    dispatch(setLoadingState(false))
  };

  const onResponseError = async (value) => {
    dispatch(appendMessage({ sender: CHAT_SENDER.AGENT, message: 'Cannot process the request.' }))
    dispatch(setLoadingState(false))
  };

  useEffect(() => {
    if (accessToken) {
      const socket = io(URL, {
        autoConnect: false,
        transports: ["websocket"],
        // auth: {
        //   token: `Bearer ${accessToken}`
        // },
      });
      setWebsocket(socket);
    }
  }, [accessToken]);

  const onWebsocketConnect = () => dispatch(setWebsocketState(true));
  const onWebsocketDisconnect = () => dispatch(setWebsocketState(false));

  useEffect(() => {
    if (websocket) {
      websocket.connect()
      websocket.on(EVENTS_ACTION_TYPE.RESPONSE, onResponse);
      websocket.on(EVENTS_ACTION_TYPE.RESPONSE_ERROR, onResponseError);
      websocket.on('connect', onWebsocketConnect);
      websocket.on('disconnect', onWebsocketDisconnect);

      return () => {
        websocket.off(EVENTS_ACTION_TYPE.RESPONSE, onResponse);
        websocket.off(EVENTS_ACTION_TYPE.RESPONSE_ERROR, onResponseError);
        websocket.disconnect();
      };
    }
  }, [websocket]);

  return (
    <SocketContext.Provider value={{ websocket }}>
      {children}
    </SocketContext.Provider >
  )
}


export default WebsocketWrapper;
