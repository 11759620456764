import {
  createAsyncThunk,
  createSlice
} from "@reduxjs/toolkit";
import { postData } from "../../utils/api";

const initialState = {
  worksheetList: []
};

export const worksheetSlice = createSlice({
  name: "worksheet",
  initialState,
  reducers: {
    appendWorksheet: (state, action) => {
      state.worksheetList.push({ ...action.payload })
    }
  },
});



export const { appendWorksheet } = worksheetSlice.actions;
export const worksheetReducer = worksheetSlice.reducer;
