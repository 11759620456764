import {
  createAsyncThunk,
  createSlice
} from "@reduxjs/toolkit";
import { CHAT_SENDER } from "../../utils/chatUtils";
import { postData } from "../../utils/api";

const initialState = {
  websocketConnected: false,
  messages: [],
  loading: false
};

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setWebsocketState: (state, action) => {
      state.websocketConnected = action.payload;
      !action.payload && (state.loading = false);
    },
    appendMessage: (state, action) => {
      state.messages.push({ sender: action.payload.sender, message: action.payload.message })
    },
    setLoadingState: (state, action) => {
      state.loading = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(postMessage.pending, (state, action) => {
        console.log('pending post message')
      })
      .addCase(postMessage.fulfilled, (state, action) => {
        state.messages.push({ sender: CHAT_SENDER.USER, message: action.payload ?? '' })
      })
  }
});

export const postMessage = createAsyncThunk(
  "chat/postMessage",
  async (message) =>
    await postData(`/new_message`, {message: message})
)

export const { setWebsocketState, appendMessage, setLoadingState } = chatSlice.actions;
export const chatReducer = chatSlice.reducer;
